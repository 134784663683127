import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo_all_red.svg'; 
import linkedinlogo from '../assets/images/linkedin-square-green.svg'

function Footer() {

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1030px)');

    const handleMediaChange = (event) => {
      setIsMobile(event.matches);
    };

    // Set initial state
    handleMediaChange(mediaQuery);

    // Add listener for changes
    mediaQuery.addEventListener('change', handleMediaChange);

    // Clean up the listener on component unmount
    return () => mediaQuery.removeEventListener('change', handleMediaChange);
  }, []);

  return (
    <footer className="general-section" style={{ paddingTop: "60px", paddingBottom: "40px"}}>
      <div style={{ fontSize: isMobile ? "40px" :"46px", fontWeight: "bold", textAlign: "left", color: "#dbdbdb"}}>
        Let's build your next-gen app together
      </div>
      <div className="gutter-40x"></div>
      <div className="gutter-40x" style={{ display: isMobile ? "none" : "" }}></div>
      <div className="footer-container">
        {/* Left Column */}
        <div className="footer-left">
          <Link to="/">
            <img src={logo} alt="Logo" className="footer-logo" />
          </Link>
          <p>We build innovative web, mobile and AI solutions.</p>
          <a href="mailto:sales@baltoro.io" className="footer-email">sales@baltoro.io</a>
        </div>

        <div className="gutter-40x" style={{ display: isMobile ? "" : "none" }}></div>

        {/* Right Column */}
        <div className="footer-right" style={{ 
          display: isMobile ? "flex" : "", // Use flex on mobile, block on larger screens
          gap: isMobile ? "5vw" : "", // Set gap on mobile; remove gap on larger screens
          alignContent: isMobile ? "center" : "", // Center items on mobile
          justifyContent: isMobile ? "center" : "",
         }}>
          <div className="footer-subcolumn">
            {/* <h3><Link to="/services">Services</Link></h3>
            <ul>
              <li>Web Development</li>
              <li>Mobile Development</li>
              <li>DevOps Sevices</li>
            </ul> */}
          </div>
          <div className="footer-subcolumn">
            <h4>Company</h4>
            <ul>
              <li style={{ paddingBottom: "10px"}}><Link to="/about-us">About Us</Link></li>
              <li><Link to="/portfolio">Portfolio</Link></li>
            </ul>
          </div>
          <div className="footer-subcolumn">
            <h4>Follow Us On</h4>
            <ul>
              <li>
                  <a href="https://www.linkedin.com/about-us/baltoroinc/" target="_blank" rel="noopener noreferrer">
                    <img src={linkedinlogo} alt="LinkedIn Logo"/>
                  </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="gutter-40x"></div>
      <div className="gutter-40x" style={{ display: isMobile ? "none" : "" }}></div>
      <div className="gutter-40x"></div>
      {/* Copyright Text */}
      <div style={{ textAlign: 'center', color: '#d8d8d8', fontSize: "12px"}}>
        Copyright © 2024 Baltoro. All rights reserved.
      </div>
    </footer>
  );
}

export default Footer;