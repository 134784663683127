import React, { useState } from 'react';
import HttpClient from '../../api/HttpClient';  // Make sure to import HttpClient or axios
import './Contact.css'

function Contact() {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    company: '',
    website: ''
  });
  const [apiError, setApiError] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Construct the email message body
    const message = `
      Name: ${formData.fullName}\n
      Company: ${formData.company}\n
      Website: ${formData.website ? formData.website : 'N/A'}
    `;

    const emailData = {
      email: 'sales@baltoro',  // Always sending to sales@baltoro
      subject: `New Contact from ${formData.fullName}`,
      message: message
    };

    // API call to send the email
    HttpClient.post('/send-email', emailData)  // Adjust the URL if necessary
      .then(responsePayload => {
        const responseData = responsePayload.data;
        alert(responseData.message); // Show success message
      })
      .catch(error => {
        console.error('Error sending email:', error);
        setApiError('Error sending email. Please try again later.');
      });
  };

  return (
    <div className='contact-page'>
      <section className="general-section">
        <h2 className="section-heading" style={{ textAlign: "left"}}>
          Let's talk
        </h2>
        <div className="gutter-40x"></div>
        <div style={{ fontSize: "18px", textAlign: "left", color: "#dbdbdb"}}>
          Feel free to send us message about how we can collaborate and what do you want to build.
          <div className="gutter-10x"></div>
          You can also email us at <a href="mailto:hello@baltoro.io" className="footer-email">hello@baltoro.io</a>
        </div>
        <div className="gutter-40x"></div>
        <div style={{ textAlign: "left"}}>
          <form onSubmit={handleSubmit} className='contact-page-right-form'>
            <label style={{ position: 'relative', color: '#fff' }}>
              <span>Full Name <span style={{ color: '#ff4444' }}>*</span></span>
              <div>
                <input
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  placeholder="Enter full name"
                  required
                  className='contact-page-right-input'
                />
              </div>
            </label>
            <label style={{ position: 'relative', color: '#fff' }}>
              <span>Business Email <span style={{ color: '#ff4444' }}>*</span></span>
              <div>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter business email"
                required
                className='contact-page-right-input'
              />
              </div>
            </label>
            <label style={{ position: 'relative', color: '#fff' }}>
              <span>Company <span style={{ color: '#ff4444' }}>*</span></span>
              <div>
              <input
                type="text"
                name="company"
                value={formData.company}
                onChange={handleChange}
                placeholder="Enter company name"
                required
                className='contact-page-right-input'
              />
              </div>
            </label>
            <label style={{ position: 'relative', color: '#fff' }}>
              <span>Website</span>
              <div>
              <input
                type="url"
                name="website"
                value={formData.website}
                onChange={handleChange}
                placeholder="Enter website"
                className='contact-page-right-input'
              />
              </div>
            </label>
            <div>
              <button
                type="submit"
                className="dropdown-contact-button">
                Send →
              </button>
            </div>
          </form>

        </div>
      </section>
    </div>
  );
}

export default Contact;