import axios from 'axios'

// for development locally the base url is localhost
// for production using nginx proxy_pass the base url is /api
const baseURL = "http://127.0.0.1:8088"

export default axios.create({
  baseURL: baseURL,
  withCredentials: true,
  headers: {
    // 'Access-Control-Allow-Credentials': true,
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH'
  }
});