import React, { useEffect, useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLaptopCode, faMobileAlt, faCode, faServer, faUser, faProjectDiagram } from "@fortawesome/free-solid-svg-icons"
import rick from "../../assets/images/customer_rick_st.jpg"
import phillipe from "../../assets/images/customer_phillipe_gsa.png"
import melih from "../../assets/images/customer-melih.jpg"
import { Link } from "react-router-dom"
import "./Home.css"

function Home() {
  const [isVisible, setIsVisible] = useState(false)
  const sectionRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true)
        } else {
          setIsVisible(false) // Reset visibility if not in view
        }
      },
      { threshold: 0.2 } // Trigger when 10% of the element is visible
    )

    if (sectionRef.current) {
      observer.observe(sectionRef.current)
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current)
      }
    }
  }, [])


  return (
    <div className="home">
      {/* Top Section */}
      <section className="home-top-section">
        <div className="home-top-section-text-side">
          <h2 className="home-top-section-heading">
            <span style={{ color: "#ff4444"}}>We build innovative</span> web, mobile and AI solutions.
          </h2>
          <div className="home-top-section-paragraph">
            We collaborate with startups, scaleups, and enterprises to deliver high-quality, 
            innovative software and Artificial Intelligence solutions that drive success and exceed 
            expectations.
          </div>
          <div className="gutter-40x"></div>
          <div style={{ textAlign: "left"}}>
            <Link to="/contact" className="contact-button" style={{ paddingLeft: "40px", paddingRight: "40px"}}>Talk to us</Link>
          </div>
        </div>
        <div className="home-top-section-image-side">
        </div>
      </section>
      
      {/* Services Section */}
      <section className="general-section">
        <h2 className="section-heading" style={{ textAlign: "center"}}>
          Solutions we build for you
        </h2>
        <div className="gutter-40x"></div>
        <div className="services-container">
          <div className="service-box">
              <FontAwesomeIcon icon={faLaptopCode} className="service-icon" />
              <div className="gutter-40x"></div>
              <h4 className="service-name">Web Applications</h4>
              <div className="gutter-20x"></div>
              <p className="service-description">
                We build dynamic, responsive web applications that are optimized for performance, security, and scalability. Our solutions are designed to support your business growth, offering seamless user experiences that drive engagement and success across all platforms.
              </p>
          </div>
          <div className="service-box">
              <FontAwesomeIcon icon={faMobileAlt} className="service-icon" />
              <div className="gutter-40x"></div>
              <h4 className="service-name">Mobile Applications</h4>
              <div className="gutter-20x"></div>
              <p className="service-description">
                Our team develops custom mobile apps tailored to your business needs, ensuring a consistent and intuitive user experience on iOS and Android. Whether it's an enterprise solution or a consumer-focused app, we deliver powerful, scalable mobile applications that align with your goals.
              </p>
          </div>
          <div className="service-box">
              <FontAwesomeIcon icon={faCode} className="service-icon" />
              <div className="gutter-40x"></div>
              <h4 className="service-name">Artificial Intelligence</h4>
              <div className="gutter-20x"></div>
              <p className="service-description">
                We leverage AI and machine learning to transform your business processes. From predictive analytics to intelligent automation, our AI-driven solutions help you innovate, improve decision-making, and gain a competitive edge.
              </p>
          </div>
          <div className="service-box">
              <FontAwesomeIcon icon={faUser} className="service-icon" />
              <div className="gutter-40x"></div>
              <h4 className="service-name">UX Design</h4>
              <div className="gutter-20x"></div>
              <p className="service-description">
                Our user-centric design approach ensures that your digital products are not only functional but also delightful to use. We create intuitive and engaging user interfaces that enhance user satisfaction, driving better business outcomes and increased retention.
              </p>
          </div>
          <div className="service-box">
              <FontAwesomeIcon icon={faServer} className="service-icon" />
              <div className="gutter-40x"></div>
              <h4 className="service-name">DevOps Services</h4>
              <div className="gutter-20x"></div>
              <p className="service-description">
                We help businesses accelerate their development pipelines by implementing efficient DevOps practices. Our team integrates automation, continuous delivery, and agile methodologies to optimize your infrastructure, ensuring faster deployment cycles and improved reliability.
              </p>
          </div>
          <div className="service-box">
              <FontAwesomeIcon icon={faProjectDiagram} className="service-icon" />
              <div className="gutter-40x"></div>
              <h4 className="service-name">Startup Advisory</h4>
              <div className="gutter-20x"></div>
              <p className="service-description">
                Our startup advisory services provide strategic guidance to help early-stage businesses succeed. From product development to market positioning, we offer expert advice to refine your vision, secure investment, and scale your operations effectively.
              </p>
          </div>
        </div>
      </section>


      {/* Customer Review Section */}
      <section className="general-section">
        <h2 className="section-heading" style={{ textAlign: "center"}}>
          Hear it from our customers
        </h2>
        <div className="gutter-40x"></div>
        <div className="customers-container">
          <div className="customers-box">
              <img src={rick} alt="Rick Nguyen" className="customers-image"/>
              <div className="customers-content">
                  <h4 className="customers-content-heading">Rick Nguyen</h4>
                  <p className="customers-content-subheading">Co-Founder Spot Trender</p>
                  <p className="customers-content-paragraph">
                    "Baltoro has the full feature-set that we need as a serverless platform and the support team guided us throughout our transition. We trust them with building our models, as well. Baltoro is a truly predictable, cost-effective solution to our scaling computing needs."
                  </p>
              </div>
          </div>
          <div className="customers-box">
              <img src={phillipe} alt="Philippe Poelaert" className="customers-image"/>
              <div className="customers-content">
                  <h4 className="customers-content-heading">Philippe Poelaert</h4>
                  <p className="customers-content-subheading">COO Golden Set Analytics</p>
                  <p className="customers-content-paragraph">
                  "We chose Baltoro as it’s a high-performance platform equipped with parallelization, which allowed us to reduce costs for processing large videos. The team came with a well-fit solution built on its hassle-free managed environment. The platform helps us to sustain our competitive edge."
                  </p>
              </div>
          </div>
          <div className="customers-box">
              <img src={melih} alt="Melih Erdurcan" className="customers-image"/>
              <div className="customers-content">
                  <h4 className="customers-content-heading">Melih Erdurcan</h4>
                  <p className="customers-content-subheading">Bl & DSAD Modanisa</p>
                  <p className="customers-content-paragraph">
                  "Baltoro is an outstanding data scientist friendly platform that all of our teams prefer. The integrations and API feature performs remarkably in our multi-cloud setting. By just connecting the data and code, we are ready for a new project thanks to lightening fast cluster startup and all managed Spark-ready GPUs."
                  </p>
              </div>
          </div>
        </div>
        <div className="gutter-40x"></div>
        <div className="link-container">
          <Link to={"/portfolio"}><button className="service-link">View Portfolio</button></Link>
        </div>
      </section>
      
    
      {/* Contact Section */}
      <section className="general-section" style={{ paddingTop: "40px", paddingBottom: "40px", backgroundColor:"#ff4444" }} ref={sectionRef}>
        <h2 className="section-heading" 
          style={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateY(0)" : "translateY(0)", textAlign: "center" }}>
          Ready to get started?
        </h2>
        <div className="gutter-40x"></div>
        <p className="contact-section-paragraph" style={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateY(0)" : "translateY(2.5vw)" }}>
          Contact us to discover how we can support your business. We look forward to working with you.
        </p>
        <div className="gutter-40x"></div>
        <div className="link-container">
          <Link to="/contact" className="contact-section-button">Contact Us</Link>
        </div>
      </section>
    </div>
  )
}

export default Home
