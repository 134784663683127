import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home/Home';
import Company from './pages/Company/Company';
import Contact from './pages/Contact/Contact';
import CaseStudies from './pages/CaseStudies/CaseStudies';
import './App.css';

// ScrollToTop component to scroll to the top on route change
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [pathname]); // Trigger when pathname changes

  return null;
};

// Function to dynamically set metadata based on the current route
const App = () => {
  const location = useLocation();

  // Metadata based on route paths
  const metaData = {
    '/': {
      title: 'Baltoro - Home',
      description: 'Welcome to Baltoro, your partner in innovation and growth.',
      ogTitle: 'Baltoro - Home',
      ogDescription: 'Discover Baltoro\'s innovative solutions and services.',
      ogImage: 'http://www.baltoro.io/images/altoro-Logo-512x512.png',
    },
    '/about-us': {
      title: 'Baltoro - About Us',
      description: 'Learn more about Baltoro, our mission, and our team.',
      ogTitle: 'Baltoro - About Us',
      ogDescription: 'Learn about Baltoro\'s mission and the team behind our innovative solutions.',
      ogImage: 'http://www.baltoro.io/images/altoro-Logo-512x512.png',
    },
    '/contact': {
      title: 'Baltoro - Contact Us',
      description: 'Get in touch with Baltoro for any inquiries or support.',
      ogTitle: 'Baltoro - Contact Us',
      ogDescription: 'Contact Baltoro to learn more about our services and how we can assist you.',
      ogImage: 'http://www.baltoro.io/images/altoro-Logo-512x512.png',
    },
    '/portfolio': {
      title: 'Baltoro - Portfolio',
      description: 'Explore Baltoro\'s portfolio of successful projects and case studies.',
      ogTitle: 'Baltoro - Portfolio',
      ogDescription: 'Discover the projects that showcase Baltoro\'s expertise.',
      ogImage: 'http://www.baltoro.io/images/altoro-Logo-512x512.png',
    },
  };

  const currentMeta = metaData[location.pathname] || metaData['/'];

  return (
    <div className="App">
      <Helmet>
        <title>{currentMeta.title}</title>
        <meta name="description" content={currentMeta.description} />
        <meta property="og:title" content={currentMeta.ogTitle} />
        <meta property="og:description" content={currentMeta.ogDescription} />
        <meta property="og:image" content={currentMeta.ogImage} />
        <meta property="og:url" content={`https://baltoro.io/${location.pathname}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={currentMeta.title} />
        <meta name="twitter:description" content={currentMeta.description} />
      </Helmet>
      
      <div className="gutter-20x"></div>
      <Header />
      <ScrollToTop /> {/* Ensure each page scrolls to the top */}
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<Company />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/portfolio" element={<CaseStudies />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
