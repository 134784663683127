import React, { useEffect, useState, useRef } from 'react';
import './CaseStudies.css';
import STlogo from '../../assets/images/STlogo.png';
import QKlogo from '../../assets/images/QKlogo.png';
import { Row, Col} from "react-bootstrap"
import { Link } from 'react-router-dom';

function CaseStudies() {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false); // Reset visibility if not in view
        }
      },
      { threshold: 0.2 } // Trigger when 10% of the element is visible
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');

    const handleMediaChange = (event) => {
      setIsMobile(event.matches);
    };

    // Set initial state
    handleMediaChange(mediaQuery);

    // Add listener for changes
    mediaQuery.addEventListener('change', handleMediaChange);

    // Clean up the listener on component unmount
    return () => mediaQuery.removeEventListener('change', handleMediaChange);
  }, []);

  return (
    <div className="home">
       <section className="company-top-section">
        <h2 className="company-top-section-heading">
          <span style={{ color: "#ff4444"}}>Discover</span> innovative solutions, seamless integration, and tailored technology for our valuable clients
        </h2>
      </section>

      {/* spottrender */}
      <section className="general-section">
        <Row>
          <Col md="4" style={{ textAlign: "center"}}>
            <img src={STlogo} alt="Spot Trender Logo" className="box-logo" />
            <div className="gutter-10x"></div>
            <a href="https://spottrender.com" className="footer-email" target="_blank" rel="noopener noreferrer">spottrender.com</a>
            <div className="gutter-10x"></div>
            <span class="badge location-badge">USA</span>
            <div className="gutter-10x"></div>
            <p style={{ fontSize: "14px"}}>
              AI-powered Ad-Testing, Brand-Tracking, and BI Data-Collection services that drives business results
            </p>
            
          </Col>
          <Col style={{ textAlign: "left" }}>
            <p style={{ color: "#d8d8d8"}}>
              Our team has implemented new features and resolved bugs in the PHP codebase, ensuring a smoother user experience. We provided 
              AWS consulting services to optimize their cloud solutions, enhancing performance 
              and efficiency. Additionally, we modernized their tech stack to improve scalability 
              and performance while upgrading their infrastructure and database systems to support evolving needs. 
              To further enhance user engagement, we transformed their survey-taking module into a fully 
              responsive design, ensuring seamless functionality across all devices.
            </p>
            <div className="gutter-20x"></div>
            <div className="case-services">
              <span class="badge tech-stack-badge">React</span>
              &nbsp;&nbsp;
              <span class="badge tech-stack-badge">AWS Cloud Services</span>
              &nbsp;&nbsp;
              <span class="badge tech-stack-badge">Python</span>
              <div className="gutter-10x"></div>
              &nbsp;&nbsp;
              <span class="badge tech-stack-badge">MySQL</span>
              &nbsp;&nbsp;
              <span class="badge tech-stack-badge">Platform Modernization</span>
            </div>
            <div className="gutter-10x"></div>
            <div className="case-services">
              <span class="badge tech-stack-badge">Artificial Intelligence</span>
              &nbsp;&nbsp;
              <span class="badge tech-stack-badge">Analytics Pipeline</span>
            </div>

          </Col>
        </Row>
      </section>

      {/* queenken */}
      <section className="general-section">
        <Row style={{ display: isMobile ? "flex" : "", flexDirection: isMobile ? "column-reverse" : "" }}>
          <Col style={{ textAlign: "left"}}>
            <p style={{ color: "#d8d8d8"}}>
              For our client in the fashion industry, we began with an initial consultation 
              to conceptualize an innovative fashion booking platform. Our team then established the 
              necessary tooling and technology stack to bring the vision to life. We led the full 
              product development process from start to finish, ensuring that every aspect met the client’s 
              requirements. To guarantee ongoing success, we provided continuous support by managing the 
              infrastructure, handling releases, and ensuring regular maintenance of the platform.
            </p>
            <div className="gutter-20x"></div>
            <div className="case-services">
              <span class="badge tech-stack-badge">React</span>
              &nbsp;&nbsp;
              <span class="badge tech-stack-badge">Digital Ocean Cloud Services</span>
              &nbsp;&nbsp;
              <div className="gutter-10x"></div>
              <span class="badge tech-stack-badge">Python</span>
              &nbsp;&nbsp;
              <span class="badge tech-stack-badge">MySQL</span>
              &nbsp;&nbsp;
              <span class="badge tech-stack-badge">Startup Advisory</span>
            </div>
            <div className="gutter-10x"></div>
            <div className="case-services">
              <span class="badge tech-stack-badge">Payment Gateway Integration</span>
              &nbsp;&nbsp;
              <div className="gutter-10x"></div>
              <span class="badge tech-stack-badge">AWS S3 Integration</span>
            </div>
          </Col>
          <Col md="4" style={{ textAlign: "center"}}>
            <img src={QKlogo} alt="Queen Ken Logo" className="box-logo" />
            <div className="gutter-10x"></div>
            <a href="https://queenken.com" className="footer-email" target="_blank" rel="noopener noreferrer">queenken.com</a>
            <div className="gutter-10x"></div>
            <span class="badge location-badge">USA</span>
            <div className="gutter-10x"></div>
            <p style={{ fontSize: "14px"}}>
              A luxury booking platform for high profile brands + consumers
            </p>
            
          </Col>
        </Row>
      </section>

      {/* extendme */}
      <section className="general-section">
        <Row>
          <Col md="4" style={{ textAlign: "center"}}>
            <span className="extendme-logo"></span>
            <div className="gutter-10x"></div>
            <a href="https://extendme.ai" className="footer-email" target="_blank" rel="noopener noreferrer">extendme.ai</a>
            <div className="gutter-10x"></div>
            <span class="badge location-badge">USA</span>
            <div className="gutter-10x"></div>
            <p style={{ fontSize: "14px"}}>
              Improve your relationships and influence effortlessly. Help people, stay top of mind, and unlock opportunities with your Personal AI.
            </p>
            
          </Col>
          <Col style={{ textAlign: "left" }}>
            <p style={{ color: "#d8d8d8"}}>
              We designed and developed a scalable service architecture to ensure optimal 
              performance. We then containerized the solution using Docker, enabling consistency 
              across different environments. Our team also handled the full web and app development, 
              meeting the project’s specific requirements. Additionally, we integrated AI-driven 
              customization, creating a personalized clone to significantly enhance the user experience.
            </p>
            <div className="gutter-20x"></div>
            <div className="case-services">
              <span class="badge tech-stack-badge">React</span>
              &nbsp;&nbsp;
              <span class="badge tech-stack-badge">Digital Ocean Cloud Services</span>
              &nbsp;&nbsp;
              <div className="gutter-10x"></div>
              <span class="badge tech-stack-badge">Python</span>
              &nbsp;&nbsp;
              <span class="badge tech-stack-badge">MySQL</span>
              &nbsp;&nbsp;
              <span class="badge tech-stack-badge">Startup Advisory</span>
            </div>
            <div className="gutter-10x"></div>
            <div className="case-services">
              <span class="badge tech-stack-badge">Payment Gateway Integration</span>
              &nbsp;&nbsp;
              <div className="gutter-10x"></div>
              <span class="badge tech-stack-badge">AWS S3 Integration</span>
              &nbsp;&nbsp;
              <span class="badge tech-stack-badge">Whatsapp</span>
              &nbsp;&nbsp;
              <div className="gutter-10x"></div>
              <span class="badge tech-stack-badge">Artificial Intelligence</span>
            </div>
          </Col>
        </Row>
      </section>

      {/* fiveset */}
      <section className="general-section">
        <Row style={{ display: isMobile ? "flex" : "", flexDirection: isMobile ? "column-reverse" : "" }}>
          <Col style={{ textAlign: "left"}}>
            <p style={{ color: "#d8d8d8"}}>
              For this client, we focused on leveraging an open-source codebase to expedite development 
              and accelerate their time-to-market. We adapted the codebase to support a multi-tenant architecture, 
              ensuring scalability and flexibility. Additionally, we upgraded their infrastructure and 
              database systems to meet modern standards. Finally, we integrated and enhanced a new admin 
              module, replacing the existing open-source solution with improved functionality and features.
            </p>
            <div className="gutter-20x"></div>
            <div className="case-services">
              <span class="badge tech-stack-badge">React</span>
              &nbsp;&nbsp;
              <span class="badge tech-stack-badge">Digital Ocean Cloud Services</span>
              &nbsp;&nbsp;
              <div className="gutter-10x"></div>
              <span class="badge tech-stack-badge">Python</span>
              &nbsp;&nbsp;
              <span class="badge tech-stack-badge">MySQL</span>
              &nbsp;&nbsp;
              <span class="badge tech-stack-badge">Startup Advisory</span>
            </div>
            <div className="gutter-10x"></div>
            <div className="case-services">
              <span class="badge tech-stack-badge">Payment Gateway Integration</span>
              &nbsp;&nbsp;
              <span class="badge tech-stack-badge">Analytics</span>
            </div>
          </Col>
          <Col md="4" style={{ textAlign: "center"}}>
            <span className="fiveset-logo"></span>            
            <div className="gutter-10x"></div>
            <a href="https://fiveset.io" className="footer-email" target="_blank" rel="noopener noreferrer">fiveset.io</a>
            <div className="gutter-10x"></div>
            <span class="badge location-badge">Europe</span>
            <div className="gutter-10x"></div>
            <p style={{ fontSize: "14px"}}>
              Fiveset is fast, lightweight, intuitive modern data exploration and visualization platform
            </p>
            
          </Col>
        </Row>
      </section>
       

        {/* Contact Section */}
      <section className="general-section" style={{ paddingTop: "40px", paddingBottom: "40px", backgroundColor:"#ff4444" }} ref={sectionRef}>
        <h2 className="section-heading" 
          style={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateY(0)" : "translateY(0)", textAlign: "center" }}>
          Ready to get started?
        </h2>
        <div className="gutter-40x"></div>
        <p className="contact-section-paragraph" style={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateY(0)" : "translateY(2.5vw)" }}>
          Contact us to discover how we can support your business. We look forward to working with you.
        </p>
        <div className="gutter-40x"></div>
        <div className="link-container">
          <Link to="/contact" className="contact-section-button">Contact Us</Link>
        </div>
      </section>

    </div>
  );
}

export default CaseStudies;