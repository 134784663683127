import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/images/logo_all_red.svg'

function Header() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const dropdownRef = useRef(null)
  const buttonRef = useRef(null)

  const toggleDropdown = () => {
    setIsDropdownOpen(prevState => !prevState)
  }

  const handleOptionClick = () => {
    setIsDropdownOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current && !dropdownRef.current.contains(event.target) &&
        buttonRef.current && !buttonRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  return (
    <header className="general-section" style={{ paddingTop: "20px", paddingBottom: "20px"}}>
      <div className='header-logo'>
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      <nav>
        <ul>
          {/* <li><Link to="/services">Services</Link></li> */}
          <li><Link to="/portfolio">Portfolio</Link></li>
          <li><Link to="/about-us">About Us</Link></li>
        </ul>
      </nav>
      <Link to="/contact" className='contact-button'>Contact Us</Link>
      <button
        ref={buttonRef}
        className="dropdown-button"
        onClick={toggleDropdown}>
        {/* SVG Burger Icon */}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="white">
          <path d="M3 6h18M3 12h18m-18 6h18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        </svg>
      </button>
      <div ref={dropdownRef} className={`dropdown-content ${isDropdownOpen ? 'show' : ''}`} 
          style={{
            position: 'absolute',
            top: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
            padding: '20px',
            borderRadius: '8px',
            transition: 'max-height 0.5s ease, opacity 0.3s ease',
        }}>
        <div style={{ borderTop: '1px dashed rgba(255, 255, 255, 0.05)', margin: '1vw 0 1.5vw 0' }}></div>
        <ul>
          {/* <li><Link to="/services" onClick={handleOptionClick}>Services</Link></li> */}
          <li><Link to="/portfolio" onClick={handleOptionClick}>Portfolio</Link></li>
          <li><Link to="/about-us" onClick={handleOptionClick}>About Us</Link></li>
        </ul>
        <Link to="/contact" className='dropdown-contact-button' onClick={handleOptionClick}>Contact Us</Link>
      </div>
    </header>
  )
}

export default Header
