import React, { useEffect, useRef, useState } from 'react';
import './Company.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBagShopping, faUserFriends, faStar, faRecycle, faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons"
import linkedinlogo from '../../assets/images/linkedin-square-green.svg'

import { Link } from 'react-router-dom';
import { Row, Col} from "react-bootstrap"
import bilal from "../../assets/images/bilal-chaudhary.png"
import shahriyar from "../../assets/images/shahriyar-baig.png"
import umair from "../../assets/images/umair-ahmed.png"
import fahad from "../../assets/images/fahad-waseem.png"
import mannan from "../../assets/images/mannan-rais.png"


function Company() {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false); // Reset visibility if not in view
        }
      },
      { threshold: 0.2 } // Trigger when 10% of the element is visible
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div className='home'>
      {/* Top Section */}
      <section className="company-top-section">
        <div className="">
          <h2 className="company-top-section-heading">
          <span style={{ color: "#ff4444"}}>Explore</span> our team, our values, and how we craft outstanding software solutions
          </h2>
          <div className="gutter-40x"></div>
          <div style={{ textAlign: "center"}}>
            <Link to="/contact" className="contact-button" style={{ paddingLeft: "40px", paddingRight: "40px"}}>Talk to us</Link>
          </div>
        </div>
        <div className="home-top-section-image-side">
        </div>
      </section>

      {/* Our Mission */}
      <section className="company-top-section">
        <div className="">
          <h2 className="company-mission-section-heading">
            <FontAwesomeIcon icon={faQuoteLeft} className="service-icon" style={{ color: "#fff", marginRight: "20px"}} />
              Our mission is to empower businesses through innovative technology, while putting customer care and collaboration at the forefront of everything we do
            <FontAwesomeIcon icon={faQuoteRight} className="service-icon" style={{ color: "#fff", marginLeft: "20px"}} />
          </h2>
        </div>
        <div className="home-top-section-image-side">
        </div>
      </section>

      {/* Our story */}
      <section className="general-section">
        <h2 className="section-heading" style={{ textAlign: "center"}}>
          Our story so far
        </h2>
        <div className="gutter-40x"></div>
        <div style={{ fontSize: "18px", textAlign: "left", color: "#dbdbdb"}}>
          <p>
            Baltoro began in 2021 as a startup with a vision to democratize AI and ML across industries. 
          Initially focused on developing an end-to-end AI product, we gained deep expertise in diverse 
          tech stacks and the full product lifecycle, from inception to market launch. This journey gave 
          us a strong foundation in building scalable, innovative solutions and the operational know-how of 
          running a startup. Leveraging this experience, we pivoted to become a boutique technology services 
          company, providing high-quality, custom software solutions. Today, our talented team combines 
          technical prowess with entrepreneurial insight to help clients—ranging from startups to established 
          enterprises—turn their vision into reality.
          </p>
          <p>
            Headquartered in the USA, with satellite offices in Turkey and Pakistan, Baltoro is positioned to 
            offer global reach while maintaining personalized service.
          </p>
          <p>
            As we move forward, Baltoro is committed to growing our portfolio by building long-term 
            partnerships with both new and existing clients. Our focus remains on staying ahead of 
            technological trends, continuously evolving our expertise in AI, mobile and web applications, 
            and other cutting-edge technologies.
          </p>
        </div>
        <div className="gutter-40x"></div>
        <Row>
          <Col>
          <div className="highlight-box">
                <FontAwesomeIcon icon={faBagShopping} className="service-icon" />
                <div className="gutter-10x"></div>
                <h1 className="service-name" style={{ fontSize: "60px"}}>10+</h1>
                <p className="service-description">
                  Serving Valuable 
                  <br />
                  Clients
                </p>
            </div>
          </Col>
          <Col>
            <div className="highlight-box">
                <FontAwesomeIcon icon={faRecycle} className="service-icon" />
                <div className="gutter-10x"></div>
                <h1 className="service-name" style={{ fontSize: "60px"}}>95+</h1>
                <p className="service-description">
                  Client Satisfaction / Retention Rate
                </p>
            </div>
          </Col>
          <Col>
            <div className="highlight-box">
                <FontAwesomeIcon icon={faStar} className="service-icon" />
                <div className="gutter-10x"></div>
                <h1 className="service-name" style={{ fontSize: "60px"}}>50+</h1>
                <p className="service-description">
                  Years of combined team experience
                </p>
            </div>
          </Col>
          <Col>
            <div className="highlight-box">
              <FontAwesomeIcon icon={faUserFriends} className="service-icon" />
              <div className="gutter-10x"></div>
              <h1 className="service-name" style={{ fontSize: "60px"}}>10+</h1>
              <p className="service-description">
                Team 
                <br />
                Members
              </p>
            </div>
          </Col>
        </Row>
      </section>

      {/* Team */}
      <section className="general-section">
        <h2 className="section-heading" style={{ textAlign: "center"}}>
          Our core team
        </h2>
        <div className="gutter-40x"></div>
        <div style={{ fontSize: "18px", textAlign: "center", color: "#dbdbdb"}}>
          <p>
            Meet our driven team of experts, empowered by insights from industry lead board to deliver exceptional results
          </p>
        </div>
        <div className="gutter-40x"></div>
        <div className="team-container">
          <div className="team-box">
              <img src={bilal} alt="Bilal Chaudhry" className="team-profile-image"/>
              <div className="team-content">
                  <h4 className="team-content-heading">Bilal Chaudhry</h4>
                  <p className="team-content-subheading" style={{ fontSize: "16px !important"}}>
                    Dev Team Lead
                    <br />
                    Full-stack Engineer
                  </p>
                  <div>
                    <a href="https://www.linkedin.com/in/bilal269/" target="_blank" rel="noopener noreferrer">
                      <img src={linkedinlogo} alt="LinkedIn Logo"/>
                    </a>
                  </div>
              </div>
          </div>
          <div className="team-box">
              <img src={shahriyar} alt="Shahriyar Baig" className="team-profile-image"/>
              <div className="team-content">
                  <h4 className="team-content-heading">Shahriyar Baig</h4>
                  <p className="team-content-subheading">
                    Front-end Engineer
                    <br />
                    React Native Developer
                  </p>
                  <div>
                    <a href="https://linkedin.com/in/mirza289/" target="_blank" rel="noopener noreferrer">
                      <img src={linkedinlogo} alt="LinkedIn Logo"/>
                    </a>
                  </div>
              </div>
          </div>
          <div className="team-box">
              <img src={umair} alt="Umair Bin Ahmad" className="team-profile-image"/>
              <div className="team-content">
                  <h4 className="team-content-heading">Umair Bin Ahmad</h4>
                  <p className="team-content-subheading">
                    Full-stack Engineer
                    <br />
                    Data Scientist & AI Engineer
                  </p>
                  <div>
                    <a href="https://www.linkedin.com/in/umair-bin-ahmad/" target="_blank" rel="noopener noreferrer">
                      <img src={linkedinlogo} alt="LinkedIn Logo"/>
                    </a>
                  </div>
              </div>
          </div>
        </div>
        <div className="gutter-40x hide"></div>
        <div className="team-container">
          <div className="team-box">
              <img src={fahad} alt="Fahad Waseem" className="team-profile-image"/>
              <div className="team-content">
                  <h4 className="team-content-heading">Fahad Waseem</h4>
                  <p className="team-content-subheading">
                    Full-stack Engineer
                    <br />
                    React Native Developer
                  </p>
                  <div>
                    <a href="https://www.linkedin.com/in/fahadwb/" target="_blank" rel="noopener noreferrer">
                      <img src={linkedinlogo} alt="LinkedIn Logo"/>
                    </a>
                  </div>
              </div>
          </div>
          <div className="team-box">
              <img src={mannan} alt="Abdul Mannan" className="team-profile-image"/>
              <div className="team-content">
                  <h4 className="team-content-heading">Abdul Mannan</h4>
                  <p className="team-content-subheading">
                    Software Engineer
                    <br />
                    React Native Developer
                  </p>
                  <div>
                    <a href="https://www.linkedin.com/in/mannan610/" target="_blank" rel="noopener noreferrer">
                      <img src={linkedinlogo} alt="LinkedIn Logo"/>
                    </a>
                  </div>
                </div>
          </div>
        </div>
      </section>

      {/* Join the team */}
      <section className="general-section">
        <h2 className="section-heading" style={{ textAlign: "center"}}>
          Join our team
        </h2>
        <div className="gutter-40x"></div>
        <div style={{ fontSize: "18px", textAlign: "center", color: "#dbdbdb"}}>
          <p>
            Join our awesome tech team! Discover exciting career opportunities and help us create cutting-edge technology and AI solutions for clients across the globe
          </p>
        </div>
        <div className="gutter-40x"></div>
        <div className="join-box">
          <Row>
            <Col style={{ fontSize: "20px"}}>
              <b>React Native Developer (iOS)</b> - Internship - Remote
            </Col>
            <Col className='hide' style={{ textAlign: "right"}}>
              <Link to="mailto:career@baltoro.io" className="contact-button" style={{ paddingLeft: "40px", paddingRight: "40px"}}>Apply</Link>
            </Col>
          </Row>
        </div>
        <div className="gutter-20x"></div>
        <div className="join-box">
          <Row>
            <Col style={{ fontSize: "20px"}}>
              <b>Full-stack Engineer</b> - Internship - Remote
            </Col>
            <Col className='hide' style={{ textAlign: "right"}}>
              <Link to="mailto:career@baltoro.io" className="contact-button" style={{ paddingLeft: "40px", paddingRight: "40px"}}>Apply</Link>
            </Col>
          </Row>
        </div>
      </section>

      {/* Contact Section */}
      <section className="general-section" style={{ paddingTop: "40px", paddingBottom: "40px", backgroundColor:"#ff4444" }} ref={sectionRef}>
        <h2 className="section-heading" 
          style={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateY(0)" : "translateY(0)", textAlign: "center" }}>
          Ready to get started?
        </h2>
        <div className="gutter-40x"></div>
        <p className="contact-section-paragraph" style={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateY(0)" : "translateY(2.5vw)" }}>
          Contact us to discover how we can support your business. We look forward to working with you.
        </p>
        <div className="gutter-40x"></div>
        <div className="link-container">
          <Link to="/contact" className="contact-section-button">Contact Us</Link>
        </div>
      </section>

    </div>
  );
}

export default Company;
